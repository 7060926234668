.react-datetime-picker__calendar-button {
  color: rgba(0, 0, 0, 0.54);
}

.react-datetime-picker__wrapper {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.react-calendar {
  border: none;
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}

.react-calendar__tile--now,
.react-calendar__tile--now:enabled:hover {
  background: none;
}

.react-calendar__tile--active {
  background-color: #3f51b5 !important;
}

.react-calendar__navigation__arrow,
.react-calendar__tile {
  border-radius: 50%;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.react-calendar__tile {
  padding: 0.95em 0.5em;
}

.react-calendar__tile:disabled,
.react-calendar__navigation button[disabled] {
  background: none;
  opacity: 0.5;
}

.react-calendar__navigation button:enabled:hover, 
.react-calendar__navigation button:enabled:focus,
.react-calendar__tile:enabled:hover, 
.react-calendar__tile:enabled:focus {
  background-color: rgba(0, 0, 0, 0.04);
}

.react-calendar__navigation__label {
  border-radius: 50%;
}

.react-datetime-picker__inputGroup {
  min-width: 180px;
}